import { defineMessages } from 'react-intl';

const messages = defineMessages({
	add: {
		id: 'admin.users.button.add',
		description: 'Add button text',
		defaultMessage: 'Add',
	},
	addUser: {
		id: 'admin.users.button.addUser',
		description: 'Add user (singular) button text',
		defaultMessage: 'Add user',
	},
	addProductEnvironmentsHeader: {
		id: 'admin.users.addProductEnvironments.header',
		description: 'Add product environments header',
		defaultMessage: 'Add product environments',
	},
	addProductEnvironmentsItem: {
		id: 'admin.users.addProductEnvironments.item',
		description: 'Add product environments item',
		defaultMessage:
			'Select the product environments where you want to provision the users.',
	},
	addAdministrator: {
		id: 'admin.users.button.addAdministrator',
		description: 'Add administrator button text',
		defaultMessage: 'Add Administrator',
	},
	makeAdminItem: {
		id: 'admin.users.menu.makeAdmin',
		description: 'Promote to admin menu item text',
		defaultMessage: 'Promote to administrator',
	},
	makeSuperAdminItem: {
		id: 'admin.users.menu.makeSuperAdmin',
		description:
			'Make Super Admin menu item text.  (only visible to internal Planview super admins)',
		defaultMessage: 'Set role: Super admin',
	},
	makeUserAdminSuccess: {
		id: 'admin.users.makeUserAdminSuccess',
		description:
			'Text of success / non failure response to make admin for a user',
		defaultMessage:
			'The selected user is now a Planview Admin administrator.',
	},
	makeUserAdminSuccessPlural: {
		id: 'admin.users.makeUserAdminSuccessPlural',
		description:
			'Text of success / non failure response to make admin for multiple users',
		defaultMessage:
			'The {count} selected users are now Planview Admin administrators.',
	},
	makeSuperAdminSuccess: {
		id: 'admin.users.makeSuperAdminSuccess',
		description:
			'Text of success / non failure response to make super admin for a user',
		defaultMessage: 'The selected user is now a super administrator.',
	},
	makeSuperAdminSuccessPlural: {
		id: 'admin.users.makeSuperAdminSuccessPlural',
		description:
			'Text of success / non failure response to make super admin for multiple users',
		defaultMessage:
			'The {count} selected users are now super administrators.',
	},
	makeCustomerCareSuccess: {
		id: 'admin.users.makeCustomerCareSuccess',
		description:
			'Text of success / non failure response to make the user a customer care administrator',
		defaultMessage:
			'The selected user is now a customer care administrator.',
	},
	makeCustomerCareSuccessPlural: {
		id: 'admin.users.makeCustomerCareSuccessPlural',
		description:
			'Text of success / non failure response to make the user a customer care administrator',
		defaultMessage:
			'The {count} selected users are now customer care administrators.',
	},
	makeUserRoleSuccess: {
		id: 'admin.users.makeUserRoleSuccess',
		description:
			'Text of success toast to make the user a regular user role',
		defaultMessage: 'The selected user was set to user role.',
	},
	makeUserRoleSuccessPlural: {
		id: 'admin.users.makeUserRoleSuccessPlural',
		description:
			'Plural text of success toast to make the user a regular user role',
		defaultMessage: 'The {count} selected users were set to user role.',
	},
	removeAdminItem: {
		id: 'admin.users.menu.removeAdmin',
		description: 'Remove admin role menu item text',
		defaultMessage: 'Remove administrator role',
	},
	makeCustomerCare: {
		id: 'admin.users.menu.makeCustomerCare',
		description:
			'Set a user to customer care role (only visible to internal Planview super admins)',
		defaultMessage: 'Set role: Customer care',
	},
	makeUser: {
		id: 'admin.users.menu.makeUser',
		description:
			'Set a user to basic user role (only visible to internal Planview super admins)',
		defaultMessage: 'Set role: User',
	},
	removeUserAdminSuccess: {
		id: 'admin.users.removeUserAdminSuccess',
		description:
			'Text of success / non failure response to remove admin role for a user',
		defaultMessage:
			'The selected user is no longer a Planview Admin administrator.',
	},
	removeUserAdminSuccessPlural: {
		id: 'admin.users.removeUserAdminSuccessPlural',
		description:
			'Text of success / non failure response to remove admin role for multiple users',
		defaultMessage:
			'The {count} selected users are no longer Planview Admin administrators.',
	},
	importUsersDialogHeader: {
		id: 'admin.users.importUsersDialogHeader',
		description: 'Header for import users dialog',
		defaultMessage: 'Import users',
	},
	importUsersButtonText: {
		id: 'admin.users.importUsersButtonText',
		description: 'Text for import users button',
		defaultMessage: 'Import',
	},
	importUsersItem: {
		id: 'admin.users.menu.importUsers',
		description: 'Import users menu item text',
		defaultMessage: 'Import users',
	},
	importUsersDialogText: {
		id: 'admin.users.importUsersDialogText',
		description: 'Text for import users dialog',
		defaultMessage: 'Select a CSV file containing the users to import.',
	},
	importUsersApplicationSelectionMessage: {
		id: 'admin.users.importUsersApplicationSelectionMessage',
		description:
			'Subheader for the list of applications that can be assigned to imported user',
		defaultMessage:
			'Select product environments to be enabled for the imported users:',
	},
	downloadTemplateLink: {
		id: 'admin.users.downloadTemplateLink',
		description: 'Text for download template link',
		defaultMessage: 'Download template',
	},
	deleteUserItem: {
		id: 'admin.users.menu.deleteUser',
		description: 'Delete user menu item text',
		defaultMessage: 'Delete user',
	},
	deleteUserSuccess: {
		id: 'admin.users.deleteUserSuccess',
		description: 'Text of success / non failure response to delete user',
		defaultMessage: 'The user has been deleted.',
	},
	deleteUserSuccessPlural: {
		id: 'admin.users.deleteUserSuccessPlural',
		description:
			'Text of success / non failure response to delete multiple users',
		defaultMessage: 'The {count} users have been deleted.',
	},
	deactivateUserItem: {
		id: 'admin.users.menu.deactivateUser',
		description: 'Deactivate user menu item text',
		defaultMessage: 'Deactivate user',
	},
	activateUserItem: {
		id: 'admin.users.menu.activateUser',
		description:
			'Activate user menu item text and header text for activation modal',
		defaultMessage: 'Activate user',
	},
	activateUserItemPlural: {
		id: 'admin.users.menu.activateUserPlural',
		description: 'Bulk activate user item text and menu header text',
		defaultMessage: 'Activate users',
	},
	activateUserButton: {
		id: 'admin.users.menu.activateUser.button',
		description: 'Button text for the Activate user dialog',
		defaultMessage: 'Activate',
	},
	resendInviteSuccess: {
		id: 'admin.users.resendInviteSuccess',
		description:
			'Text of success / non failure response to resend invitation email',
		defaultMessage: 'An invitation email has been resent to {email}.',
	},
	resendInviteSuccessPlural: {
		id: 'admin.users.resendInviteSuccessPlural',
		description:
			'Text of success / non failure response to resend multiple invitation emails',
		defaultMessage:
			'{count} invitation emails have been resent to the selected emails.',
	},
	editUserItem: {
		id: 'admin.users.menu.editUser',
		description: 'Edit User menu item text',
		defaultMessage: 'Edit user',
	},
	resetPasswordItem: {
		id: 'admin.users.grid.actionsMenu.resetPasswordItem',
		description: 'Reset password menu item text',
		defaultMessage: 'Reset password',
	},
	resetPasswordSuccess: {
		id: 'admin.users.resetPasswordSuccess',
		description:
			'Text of success / non failure response to reset password for user',
		defaultMessage:
			'Reset password email has been sent to the selected user.',
	},
	resetPasswordSuccessPlural: {
		id: 'admin.users.resetPasswordSuccessPlural',
		description:
			'Text of success / non failure response to reset password for multiple user',
		defaultMessage:
			'Reset password emails have been sent to the {count} selected users.',
	},
	deleteButton: {
		id: 'admin.users.delete.confirmation.yes',
		description: 'Button text for delete',
		defaultMessage: 'Delete',
	},
	noButton: {
		id: 'admin.users.delete.confirmation.no',
		description: 'Button text for No',
		defaultMessage: 'No',
	},
	exportUsersItem: {
		id: 'admin.users.actionsMenu.exportUsersItem',
		description: 'Actions menu item to export users to a CSV file',
		defaultMessage: 'Export users',
	},
	exportInProgress: {
		id: 'admin.users.exportUsersInProgress',
		description: 'Text in popup (toast) message that exporting is going on',
		defaultMessage:
			'Export of users has started. The file will download once complete.',
	},
	exportUsersFilename: {
		id: 'admin.users.exportUsersFilename',
		description:
			'Filename for the users export. Must end with .csv. Not sure if the main part of filename should be localized or not',
		defaultMessage: 'Users.csv',
	},
	deleteHeader: {
		id: 'admin.users.delete.confirmation.header',
		description: 'Header text for delete user confirmation dialog',
		defaultMessage: 'Delete User?',
	},
	deleteContent: {
		id: 'admin.users.delete.confirmation.content',
		description: 'Content for delete user confirmation dialog',
		defaultMessage:
			'This will delete the user with the email address "{email}".',
	},
	deleteContentPlural: {
		id: 'admin.users.delete.confirmation.content.plural',
		description: 'Content for deleting 2 or more users confirmation dialog',
		defaultMessage: 'This will delete the {count} selected users.',
	},
	deactivateHeader: {
		id: 'admin.users.deactivate.confirmation.header',
		description:
			'Header text and button text for deactivate user confirmation dialog',
		defaultMessage: 'Deactivate user',
	},
	deactivateHeaderPlural: {
		id: 'admin.users.deactivate.confirmation.headerPlural',
		description:
			'Header text and button text for deactivate mulitple users confirmation dialog',
		defaultMessage: 'Deactivate users',
	},
	deactivateContent: {
		id: 'admin.users.deactivate.confirmation.content',
		description: 'Content for deactivate user confirmation dialog',
		defaultMessage:
			'This will deactivate the user with the email address "{email}". They will be deprovisioned from all environments.',
	},
	deactivateContentPlural: {
		id: 'admin.users.deactivate.confirmation.content.plural',
		description:
			'Content for deactivating 2 or more users confirmation dialog',
		defaultMessage:
			'This will deactivate the {count} selected users. They will be deprovisioned from all environments.',
	},
	deactivateContentBottomUp: {
		id: 'admin.users.deactivate.bottom.up.confirmation.content',
		description:
			'Content for deactivate user confirmation dialog in a bottom up org',
		defaultMessage:
			'This will deactivate the user with the email address "{email}". They will no longer be able to access or authenticate with Planview Admin.',
	},
	deactivateContentPluralBottomUp: {
		id: 'admin.users.deactivate.bottom.up.confirmation.content.plural',
		description:
			'Content for deactivating 2 or more users confirmation dialog in a bottom up org',
		defaultMessage:
			'This will deactivate the {count} selected users. They will no longer be able to access or authenticate with Planview Admin.',
	},
	actionsButton: {
		id: 'admin.users.actionsButton',
		description: 'Actions toolbar button',
		defaultMessage: 'Actions',
	},
	filterNoProductsMapped: {
		id: 'admin.users.noProductsMapped',
		description:
			'Filter option for finding user(s) that have no products mapped',
		defaultMessage: 'No products mapped',
	},
	userProvisionStatusHeader: {
		id: 'admin.users.userProvisionStatusHeader',
		description: 'Filter header for user provision status',
		defaultMessage: 'Provisioning Status',
	},
	userStatusHeader: {
		id: 'admin.users.userStatusHeader',
		description: 'Filter header for user status',
		defaultMessage: 'Status',
	},
	userStatusActiveUser: {
		id: 'admin.users.userStatusActiveUser',
		description: 'Filter option for active user',
		defaultMessage: 'Active',
	},
	userStatusPendingUser: {
		id: 'admin.users.userStatusPendingUser',
		description: 'Filter option for pending user',
		defaultMessage: 'Pending',
	},
	userAdminHeader: {
		id: 'admin.users.userAdminHeader',
		description: 'Filter header for is admin',
		defaultMessage: 'Admin',
	},
	yesFilterOption: {
		id: 'admin.users.filter.yes',
		description: 'Filter text for Yes',
		defaultMessage: 'Yes',
	},
	noFilterOption: {
		id: 'admin.users.filter.no',
		description: 'Filter text for No',
		defaultMessage: 'No',
	},
	errorFilterOption: {
		id: 'admin.users.filter.error',
		description: 'Filter header for Error',
		defaultMessage: 'Error',
	},
	user: {
		id: 'admin.users.user',
		description: 'Placeholder for name, email, or login alias',
		defaultMessage: 'User',
	},
	seeDetails: {
		id: 'admin.users.seeDetails',
		description:
			'Label on toast message to see details of provisioning errors',
		defaultMessage: 'See details',
	},
	provisioningErrors: {
		id: 'admin.users.provisioningErros',
		description:
			'Label on toast message when there are provisioning errors on the page',
		defaultMessage:
			'There are provisioning errors, please review and resolve.',
	},
	currentUsersTab: {
		id: 'admin.users.currentUsersTab',
		description: 'Text for Users subtab showing current users',
		defaultMessage: 'Current users',
	},
	deactivatedUsersTab: {
		id: 'admin.users.deactivatedUsersTab',
		description: 'Text for Users subtab showing deactivated users',
		defaultMessage: 'Deactivated users',
	},
	resendInviteItem: {
		id: 'admin.users.resendInvite',
		description: 'Resend invitation menu item text',
		defaultMessage: 'Resend invitation',
	},
	deactivateUserSuccess: {
		id: 'admin.users.deactivateUserSuccess',
		description:
			'Text of success / non failure response to deactivating a user',
		defaultMessage: 'User deactivated.',
	},
	deactivateUserSuccessPlural: {
		id: 'admin.users.deactivateUserSuccessPlural',
		description:
			'Text of success / non failure response to deactivating multiple users',
		defaultMessage: '{count} users deactivated.',
	},
	activateUserSuccess: {
		id: 'admin.users.activateUserSuccess',
		description:
			'Text of success / non failure response to activating a user',
		defaultMessage: 'User reactivated.',
	},
	activateUserSuccessPlural: {
		id: 'admin.users.activateUserSuccessPlural',
		description:
			'Text of success / non failure response to activating multiple users',
		defaultMessage: '{count} users reactivated.',
	},
	activationJobStarted: {
		id: 'admin.users.activationJobStarted',
		description:
			'Text to append to the beginning of the bulk reactivation toast message',
		defaultMessage: 'User reactivation has begun.',
	},
	activateUserResyncNote: {
		id: 'admin.users.activateUserResyncNote',
		description:
			'Text for reactivating user in Bottom Up customer instructing admin to resync products to reprovision',
		defaultMessage:
			'Note: This user will be automatically added to their previous products during the routine sync cycle. If you need them to be added immediately, please use the Sync users action in each product.',
	},
	activateUserResyncNotePlural: {
		id: 'admin.users.activateUserResyncNotePlural',
		description:
			'Text for reactivating user in Bottom Up customer instructing admin to resync products to reprovision',
		defaultMessage:
			'Note: These users will be automatically added to their previous products during the routine sync cycle. If you need them to be added immediately, please use the Sync users action in each product.',
	},
	enablePasswordLogin: {
		id: 'admin.users.enablePasswordLogin',
		description: 'Enable password-based login menu item text',
		defaultMessage: 'Enable password-based login',
	},
	enablePasswordLoginSuccess: {
		id: 'admin.users.enablePasswordLoginSuccess',
		description:
			'Text of success / non failure response to enable password login for a user',
		defaultMessage: 'Password-based login is enabled for this user.',
	},
	enablePasswordLoginSuccessPlural: {
		id: 'admin.users.enablePasswordLoginSuccessPlural',
		description:
			'Text of success / non failure response to enable password login for multiple users',
		defaultMessage:
			'Password-based login is enabled for {count} selected users.',
	},
	disablePasswordLogin: {
		id: 'admin.users.disablePasswordLogin',
		description: 'Disable password-based login menu item text',
		defaultMessage: 'Disable password-based login',
	},
	disablePasswordLoginSuccess: {
		id: 'admin.users.disablePasswordLoginSuccess',
		description:
			'Text of success / non failure response to disable password login for a user',
		defaultMessage: 'Password-based login is disabled for this user.',
	},
	disablePasswordLoginSuccessPlural: {
		id: 'admin.users.disablePasswordLoginSuccessPlural',
		description:
			'Text of success / non failure response to disable password login for multiple users',
		defaultMessage:
			'Password-based login is disabled for {count} selected users.',
	},
});

export default messages;
